import React, { useState, useEffect, useRef } from 'react';
import logo from './assets/logo.png';
import arrowsAbout from './assets/arrows_about.svg';
import arrows2 from './assets/arrows2.svg';
import roadmap from './assets/roadmap.svg';
import carousel1 from './assets/carousel1.png';
import carousel2 from './assets/carousel2.png';
import carousel3 from './assets/carousel3.png';
import carousel4 from './assets/carousel4.png';
import doc3 from './assets/Clark Tech Boost Program - 05 July 2022_PM.pdf';
import doc4 from './assets/Clark Tech Quest Program - 05 July 2022_PM.pdf';

import './App.scss';

function useWindowDimensions() {
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function App() {
  const date = new Date().getFullYear();

  const delay = 8000;

  const [index, setIndex] = useState(0);
  const windowSize = useWindowDimensions();
  const [useMobile, setUseMobile] = useState(windowSize.width < 796);
  const carouselItems = [1, 2, 3, 4];

  useEffect(() => {
    setUseMobile(windowSize.width < 796);
  }, [windowSize]);

  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () => setIndex((prevIndex) => (prevIndex === carouselItems.length - 1 ? 0 : prevIndex + 1)),
      delay,
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <div className="cwa-container">
      <div className="cwa-top">
        <img className="cwa-top--logo" src={logo} />
        <div className="content-container">
          <>
            <h1 className="cwa-top--big">Welcome to the CyberSecurity Workforce Alliance</h1>
            <div className="cwa-top--sub">
              We accelerate and scale the next generation of cybersecurity professionals to become
              better-prepared viable cybersecurity and risk candidates.
            </div>
          </>
        </div>
      </div>
      <div className="content-container">
        <div className="cwa-about">
          <div className="cwa-about--title">About the CWA</div>
          <div className="cwa-about--content">
            The CWA mobilizes industry, government, and education with a mission to improve the
            technology risk and cybersecurity skills and capabilities for college students and the
            professional workforce, so they are more attractive to hire, reducing the time between
            being hired and productivity.
          </div>
        </div>
      </div>
      <div className="benefits-container">
        <img className="programas-triangles" src={arrowsAbout} alt="" />
        <img className="programas-triangles-2" src={arrows2} alt="" />
        <div className="content-container">
          <div className="programas-title">Benefits</div>
          <div className="programas-content-container">
            <div className="programas-item">
              <div className="programas-item--title">Real-world foundational and soft skills</div>
              <div className="programas-item--line" />
              <div className="programas-item--content">
                Learners develop the language of risk, an understanding of the workplace context,
                and the "soft skills" that employers are looking for.
              </div>
            </div>
            <div className="programas-item">
              <div className="programas-item--title">Mentored Workplace Experience</div>
              <div className="programas-item--line" />
              <div className="programas-item--content">
                CWA industry professionals mentor learners through real-world scenarios. Learners
                gain work-based experience including how to leverage NIST and other gold-standard
                frameworks.
              </div>
            </div>
            <div className="programas-item">
              <div className="programas-item--title">Industry Credentialed with OJT hours</div>
              <div className="programas-item--line" />
              <div className="programas-item--content">
                Mentored team and role-based scenarios translate to on-the-job (OJT) training,
                utilizing industry-recognized credentials and stackable macro-credentials.
              </div>
            </div>
          </div>
          <div className="programas-btn-container">
            <a
              target="_blank"
              rel="noreferrer"
              href="mailto:info@iq4.com"
              className="programas-btn"
            >
              Find out more or join the CWA
            </a>
          </div>
        </div>
      </div>
      <div className="content-container cwa-programs__container">
        <div className="cwa-programs">
          <div className="cwa-programs--title">Our Service2Cyber Program:</div>
          <div className="cwa-about--content cwa-programs--sub">
            <p>A cybersecurity skills and proficiency training program for Military and Veterans. The purpose of the program is to provide cybersecurity workforce development training with employer mentors, leading to career pathways and job placement. Initiated by the CEO for Military Alliance representing Corporate America.</p>
            <p>Includes real-world cybersecurity cases, job roles, mentoring, and an industry recognized credential.</p>
            <p>No cost to military in transition, veterans, and family dependents.</p>
          </div>
        </div>
        <div className="our-programs-content-container">
          <div className="our-programs-item our-programs-item-2">
            <div className="our-programs-item--top">60 hours</div>
            <div className="our-programs-item--title-2">The Threat Within and</div>
            <div className="our-programs-item--title-2">NIST Framework</div>
            <div className="our-programs-item--free-2">Free</div>
            <div className="our-programs-item--content-0">
              For military in transition, veterans, and family dependents
            </div>
            <div className="our-programs-item--content-3">350 hours OJT</div>
            <div className="our-programs-btn-container-2">
              <a
                href="https://form.jotform.com/230925540258052"
                target="_blank"
                rel="noreferrer"
                className="our-programs-item--button-3"
              >
                Details and Apply Now
              </a>
            </div>
            <div className="our-programs-item--line-1" style={{ backgroundColor: '#42bce5' }} />
          </div>
          <div className="our-programs-item our-programs-item-2">
            <div className="our-programs-item--top">60 hours</div>
            <div className="our-programs-item--title-2">Cloud Technology</div>
            <div className="our-programs-item--free" style={{ marginTop: 60 }}>Free</div>
            <div className="our-programs-item--content-0">
              For military in transition, veterans, and family dependents
            </div>
            <div className="our-programs-item--content-3">350 hours OJT</div>
            <div className="our-programs-btn-container-2">
              <a
                href="https://form.jotform.com/230925851733156"
                target="_blank"
                rel="noreferrer"
                className="our-programs-item--button-3"
              >
                Details and Apply Now
              </a>
            </div>
            <div className="our-programs-item--line-1" style={{ backgroundColor: '#02d6c2' }} />
          </div>
        </div>
      </div>
      <div className="cwa-roadmap__container">
        <div className="content-container">
          <div className="cwa-programs--title">CWA Roadmap</div>
          {!useMobile && <img src={roadmap} />}
          {useMobile && (
            <div className="slideshow">
              <div
                className="slideshowSlider"
                style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
              >
                {carouselItems.map((item, index) => (
                  <img
                    key={item}
                    src={
                      item === 1
                        ? carousel1
                        : item === 2
                        ? carousel2
                        : item === 3
                        ? carousel3
                        : carousel4
                    }
                  />
                ))}
              </div>

              <div className={useMobile ? 'slideshowDots_mobile' : 'slideshowDots'}>
                {carouselItems.map((_, idx) => (
                  <div
                    key={idx}
                    className={`slideshowDot${index === idx ? ' active' : ''}`}
                    onClick={() => {
                      setIndex(idx);
                    }}
                  ></div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="testimonials-container">
        <div className="content-container">
          <div className="testimonials-title">Testimonials</div>
          <div className="programas-content-container">
            <div className="testimonials-item">
              <div className="testimonials-item--content">
                <div className="testimonials-item--testimonio">
                  iQ4 empowered me as a woman in technology and first-generation college student
                  with the knowledge, resources, and network to navigate the cybersecurity industry.
                </div>
                <div className="testimonials-item--autor-container">
                  <div className="testimonials-item--autor">Soraya</div>
                  <div className="testimonials-item--autor">John Jay College</div>
                </div>
              </div>
            </div>
            <div className="testimonials-item">
              <div className="testimonials-item--content">
                <div className="testimonials-item--testimonio">
                  The course helped increase my breadth of knowledge and understanding of risk and
                  cybersecurity, which wasn’t taught during my formal education. It allowed me to
                  understand topics not covered in my studies and become better prepared than my
                  peers in many situations during my internship.
                </div>
                <div className="testimonials-item--autor-container">
                  <div className="testimonials-item--autor">
                    Noah, Ohio National Guard, Combat Engineer now at Eli Lilly
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonials-item">
              <div className="testimonials-item--content">
                <div className="testimonials-item--testimonio">
                  As a veteran, I know first-hand the challenges in transitioning to the civilian
                  workforce. The CWA program provides a practical course to advance cybersecurity
                  readiness in entry-level candidates. CWA’s approach to imparting workforce
                  essential skills is based on real-world, regulatory standards that are applied
                  throughout the public and private sectors.
                </div>
                <div className="testimonials-item--autor-container">
                  <div className="testimonials-item--autor">
                    James, US Army, Technical Counterintelligence Special Agent, now Federal Reserve
                    Bank of Chicago, Lead Technical Security Specialist, CWA Mentor
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer" className="iq4-footer">
        © Copyright {date} iQ4 Corp. All rights reserved
      </div>
    </div>
  );
}

export default App;
